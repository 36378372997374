

















































































































































































































































































































































































































.year-bonus-user-radio{
  border-left: 1px solid #DCDFE6;
  width: 100%;

  & .el-radio-button__inner {
    width: 100%;
    white-space: normal;
  }
}

.summary-table{
  margin-top: 15px;
  width: 100%;
  text-align: end;
  border-bottom: 1px solid #DCDFE6;
  color: #a1a1a1;

  &-name {
    text-align: start;
    font-weight: bold;
  }
}

